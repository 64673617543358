<template>
  <div class="js-common-wrap">
    <div class="js-common-head">
      <div>
        <el-button class="back-btn" @click="$goBack" icon="el-icon-arrow-left" size="small" type="primary" plain></el-button>
        <label class="label">人像详情</label>
      </div>
    </div>
    <div class="js-common-content face-details">
      <div class="avator">
        <img class="img" :src="faceInfo.photo" alt="">
        <p>人像照片</p>
      </div>
      <ul class="info-list">
        <li><label>姓名：</label><span>{{ faceInfo.name }}</span></li>
        <li><label>身份：</label><span>{{ faceInfo.idcard }}</span></li>
        <li><label>性别：</label><span>{{ sex }}</span></li>
        <li><label>生日：</label><span>{{ faceInfo.birthday }}</span></li>
        <li><label>民族：</label><span>{{ faceInfo.nation }}</span></li>
        <li><label>地址</label><span>{{ faceInfo.address }}</span></li>
        <li><label>警示：</label>
          <span v-if="faceInfo.status == 0">正常</span>
          <span v-if="faceInfo.status == 1">警示</span>
          <span v-if="faceInfo.status == 2">VIP</span>
        </li>
        <li><label>模型名称：</label><span>{{ faceInfo.modelId }}</span></li>
        <li><label>Hash：</label><span>{{ faceInfo.hash }}</span></li>
        <li><label>创建时间：</label><span>{{ faceInfo.gmtCreated }}</span></li>
        <li><label>上次修改时间：</label><span>{{ faceInfo.gmtUpdated }}</span></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      currentID: '',
      faceInfo:{}
    }
  },
  computed:{
    sex(){
      let sexText = ''
      if(this.faceInfo.gender === 1){
        sexText = '男'
      }else if(this.faceInfo.gender === 2){
        sexText = '女'
      }else{
        sexText = '未知'
      }
      return sexText
    }
  },
  mounted() {
    this.currentID = this.$route.query.id
    this.getDetails()
  },
  methods: {
    getDetails(){
      this.$axios({
        method: 'get',
        url: `api/csp/afr/v1/afr/${this.currentID}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.faceInfo = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.face-details{
  margin-top: 20px;
  display: flex;
  padding: 30px 60px;
  height: 100%;
  background: #fff;
  font-size: 14px;
  .avator{
    padding: 10px 30px 0 30px;
    .img{
      width: 180px;
      height: 180px;
    }
    p{
      margin-top: 10px;
      text-align: center;
    }
  }
  .info-list{
    li{
      margin-bottom: 10px;
      line-height: 32px;
    }
    label{
      width: 150px;
      display: inline-block;
    }
  }
}
</style>
