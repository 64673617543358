var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap" }, [
    _c("div", { staticClass: "js-common-head" }, [
      _c(
        "div",
        [
          _c("el-button", {
            staticClass: "back-btn",
            attrs: {
              icon: "el-icon-arrow-left",
              size: "small",
              type: "primary",
              plain: "",
            },
            on: { click: _vm.$goBack },
          }),
          _c("label", { staticClass: "label" }, [_vm._v("人像详情")]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "js-common-content face-details" }, [
      _c("div", { staticClass: "avator" }, [
        _c("img", {
          staticClass: "img",
          attrs: { src: _vm.faceInfo.photo, alt: "" },
        }),
        _c("p", [_vm._v("人像照片")]),
      ]),
      _c("ul", { staticClass: "info-list" }, [
        _c("li", [
          _c("label", [_vm._v("姓名：")]),
          _c("span", [_vm._v(_vm._s(_vm.faceInfo.name))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("身份：")]),
          _c("span", [_vm._v(_vm._s(_vm.faceInfo.idcard))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("性别：")]),
          _c("span", [_vm._v(_vm._s(_vm.sex))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("生日：")]),
          _c("span", [_vm._v(_vm._s(_vm.faceInfo.birthday))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("民族：")]),
          _c("span", [_vm._v(_vm._s(_vm.faceInfo.nation))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("地址")]),
          _c("span", [_vm._v(_vm._s(_vm.faceInfo.address))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("警示：")]),
          _vm.faceInfo.status == 0 ? _c("span", [_vm._v("正常")]) : _vm._e(),
          _vm.faceInfo.status == 1 ? _c("span", [_vm._v("警示")]) : _vm._e(),
          _vm.faceInfo.status == 2 ? _c("span", [_vm._v("VIP")]) : _vm._e(),
        ]),
        _c("li", [
          _c("label", [_vm._v("模型名称：")]),
          _c("span", [_vm._v(_vm._s(_vm.faceInfo.modelId))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("Hash：")]),
          _c("span", [_vm._v(_vm._s(_vm.faceInfo.hash))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("创建时间：")]),
          _c("span", [_vm._v(_vm._s(_vm.faceInfo.gmtCreated))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("上次修改时间：")]),
          _c("span", [_vm._v(_vm._s(_vm.faceInfo.gmtUpdated))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }